@import "utils/constants";

.fill-height {
  height: 100%;
}

.fill-parent {
  width: 100%;
  height: 100%;
}

.fill-width {
  width: 100%;
}

/**
 * Uses absolute positioning to center an element both vertically and horizontally.
 */
.center-absolute {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.center-relative {
  display: block;
  position: relative;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
}

/**
 * For horizontal centering in standard layouts -- does not work well with absolute layouts.
 */
.center-horizontal {
  display: block;
  margin: auto;
}

/**
 * For vertical centering in standard layouts.
 */
.center-vertical {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.container-full {
  width: 100%;
}

.container-width-half {
  width: 50%;
}

@each $name, $size in $sizes {
  .margin-#{$name} { margin: $size; }
  .margin-#{$name}-stretch { margin: $size calc($size / 2); }
  .margin-#{$name}-squish { margin: calc($size / 2) $size; }

  .margin-#{$name}-top { margin-top: $size; }
  .margin-#{$name}-bottom { margin-bottom: $size; }
  .margin-#{$name}-right { margin-right: $size; }
  .margin-#{$name}-left { margin-left: $size; }

  .padding-#{$name} { padding: $size; }
  .padding-#{$name}-stretch { padding: $size calc($size / 2); }
  .padding-#{$name}-squish { padding: calc($size / 2) $size; }

  .padding-#{$name}-top { padding-top: $size; }
  .padding-#{$name}-bottom { padding-bottom: $size; }
  .padding-#{$name}-right { padding-right: $size; }
  .padding-#{$name}-left { padding-left: $size; }

  .space-horizontal-#{$name} {
    &>:not(:first-child) {
      margin-left: $size;
    }
  }

  .space-vertical-#{$name} {
    &>:not(:first-child) {
      margin-top: $size;
    }
  }
}

@for $percent-width from 1 through 100 {
  .container-width-#{$percent-width} {
    width: percentage(calc($percent-width / 100));
    position: relative;
    float: left;
  }
}

@for $width from 0 through 100 {
  $percent: $width * 1%; // this is done to append the unit of % and also keep the value as a number
  .width-percent-#{$width} {
    width: $percent;
  }

  .height-percent-#{$width} {
    height: $percent;
  }

  .margin-top-percent-#{$width} { margin-top: $percent; }
  .margin-bottom-percent-#{$width} { margin-bottom: $percent; }
  .margin-left-percent-#{$width} { margin-left: $percent; }
  .margin-right-percent-#{$width} { margin-right: $percent; }
  .margin-percent-#{$width} { margin: $percent; }

}

@for $width from 0 through 100 {
  // MARGIN RULES
  .margin-top-#{$width} { margin-top: #{$width}px; }
  .margin-bottom-#{$width} { margin-bottom: #{$width}px; }
  .margin-left-#{$width} { margin-left: #{$width}px; }
  .margin-right-#{$width} { margin-right: #{$width}px; }
  .margin-#{$width} { margin: #{$width}px; }

  // PADDING RULES
  .padding-top-#{$width} { padding-top: #{$width}px; }
  .padding-bottom-#{$width} { padding-bottom: #{$width}px; }
  .padding-left-#{$width} { padding-left: #{$width}px; }
  .padding-right-#{$width} { padding-right: #{$width}px; }
  .padding-#{$width} { padding: #{$width}px; }
}

@for $line-limit from 0 through 10 {
  // LINE CLAMP RULES
  .line-limit-#{$line-limit} {
    display: -webkit-box;
    -webkit-line-clamp: $line-limit;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.margin-auto { margin: auto; }
.margin-top-auto { margin-top: auto; }
.margin-bottom-auto { margin-bottom: auto; }
.margin-left-auto { margin-left: auto; }
.margin-right-auto { margin-right: auto; }

.overflow-y-overlay-if-possible {
  overflow-y: scroll;
  overflow-y: overlay;
}

.overflow-auto {
  overflow: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
  overflow-y: overlay;
}

.overflow-x-scroll {
  overflow-x: scroll;
  overflow-x: overlay;
}

// MISC POSITION RULES
.position-absolute { position: absolute; }
.position-fixed { position: fixed; }
.position-relative { position: relative }
.align-right { float: right; }
.inline-block { display: inline-block; }
.display-grid { display: grid; }

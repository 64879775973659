@import "./styles/styles.scss";

.App {
  font-family: $rubik-regular;
  background: $anti-flash-white;
}

.content {
  position: relative;
  text-align: center;
  padding-top: 32px;
  > * {
    max-width: 1200px;
    margin: auto;

  }
  min-height: calc(100vh - 520px);
  @media only screen and (max-width: $medium-screen) {
    padding: 16px 24px;
  }
  @media only screen and (max-width: $large-phone) {
    padding: 16px 0;
  }
  .loading-container {
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
  }
  .error-container {
    padding-top: 30%;
    padding-bottom: 30%;
    margin-left: auto;
    margin-right: auto;
  }
  .error-buttons {
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
    .error-button {
      height: 33px;
      border-radius: 40px;
      font-size: 14px;
      line-height: 33px;
      padding: 0 16px;
      width: fit-content;
      min-width: 142px;
      background-color: $bittersweet;
      color: $white;
      letter-spacing: 0.5px;
      &:hover {
        text-decoration: none;
        color: $anti-flash-white;
        svg {
          color: $anti-flash-white;
        }
      }
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
    @media only screen and (max-width: $medium-screen) {
      flex-direction:column;
      > a {
        margin-left: auto !important;
        margin-right: auto !important;
        margin-bottom: 8px;
      }
    }
  }


  .video-metrics-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    height: 100%;
    gap: 32px;
    @media only screen and (max-width: $l) {
      gap: 0;
    }
    // video has flex-grow: 2
    // locked metrics has flex-grow: 1
  }
}

.video-player {
  padding: 0;
  width: 100%;  
  height: 100%;
}

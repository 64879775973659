@import "./utils/constants";

// 1. Your custom variables and variable overwrites.
//$global-link-color: #DA7D02;

// 2. Import default variables and available mixins.
@import 'uikit/src/scss/variables-theme.scss';
@import 'uikit/src/scss/mixins-theme.scss';

// 3. Your custom mixin overwrites.
//@mixin hook-card() { color: #000; }

// 4. Import UIkit.
@import 'uikit/src/scss/uikit-theme.scss';

.uk-dotnav {
  .uk-active > * {
    background-color: $bittersweet;
  }
}

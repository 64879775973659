@import "../../styles/utils/constants.scss";

.basic-style {
  color: $white;
  letter-spacing: 0.5px;
  text-align: left;
}

.directory {
  background: $space-cadet;
  padding: 61px 39px;
  margin: 0;
  > div {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.logo {
  width: 147px;
  height: 65px;
  margin-bottom: 29px;
}

.link-section {
  .links-container {
    line-height: 17px;
    font-size: 14px;
    > div {
      margin-bottom: 8px;
    }
    .links-header {
      text-transform: uppercase;
      color: $bittersweet;
    }
    .footer-link > a {
      color: $white;
    }
  }
}

.social-media-section {
  width: 166px;
  padding-top: 20px;
  margin-left: auto;
  margin-right: auto;
  svg {
    color: $bittersweet;
  }
}

.sub-footer {
  font-size: 14px;
  line-height: 17px;
  background: $bittersweet;
  padding: 27px 40px 33px;
}

.legal-link {
  color: $white;
  &:hover {
    color: $anti-flash-white;
  }
}

@media only screen and (max-width: $tablet) {
  .directory {
    padding: 30px;
    > * {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@import "../../styles/utils/constants";

.button {
  height: 40px;
  border-radius: 40px;
  font-size: 14px;
  line-height: 40px;
  padding: 11px 31px;
  width: fit-content;
  background-color: $bittersweet;
  color: $white;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  &:hover {
    text-decoration: none;
    color: $anti-flash-white;
    svg {
      color: $anti-flash-white;
    }
  }
}

/**
 * Basic style for a container in which elements are displayed in a row.
 */
.flex-row {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;

  flex-direction: row;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;

  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

/**
 * Base style for a container in which the elements displayed in a row with space around each element.
 */
.flex-row-around {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;

  flex-direction: row;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;

  justify-content: space-around;
  -ms-justify-content: space-around;
  -webkit-justify-content: space-around;
}

/**
 * Base style for a container in which the elements displayed in a row with space between each element.
 */
.flex-row-between {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;

  flex-direction: row;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;

  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
}

/**
 * Basic style for a container in which elements are displayed in a column.
 */
.flex-col {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;

  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
}

/**
 * Base style for a container in which the elements displayed in a column with space around each element.
 */
.flex-col-around {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;

  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;

  justify-content: space-around;
  -ms-justify-content: space-around;
  -webkit-justify-content: space-around;
}

/**
 * Base style for a container in which the elements displayed in a column with space between each element.
 */
.flex-col-between {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;

  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;

  justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
}

/**
 * Apply this style to an element in a flex-box layout and it will fill the remainder of the space given that the
 * remaining elements in the layout have fixed or computed sizes.
 */
.flex-fill-remainder {
  flex: 2;
  -ms-flex: 2;
  -webkit-flex: 2;
}

.flex-wrap {
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.flex-align-center {
  align-items: center;
  -webkit-align-items: center;
}

.flex-justify-center {
  justify-content: center;
  -webkit-justify-content: center;
}

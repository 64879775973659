@import "../../styles/utils/constants";


.summary-card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0px;
  }

  @media only screen and (max-width: $l) {
    width: 100%;
    .summary-card-title {
      margin-left: 0;
      margin-right: auto;
    }
    .summary-card-lock {
      margin-right: 0;
      margin-left: auto;
    }
    
  }

  &:first-child {
    color: $bittersweet;
  }

  .summary-card-title {
    display: flex;
    justify-content: center;
    height: 100%;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;  
    position: relative;
    z-index: 0;
  }
  .summary-card-value {
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
    z-index: 0;
  }
  .summary-card-lock {
    font-size: 14px;
    height: 28px;
    border-radius: 4px;
    background: $anti-flash-white;
    text-align: center;
    img {
      height: 20px;
      width: 16px;
      margin: 4px 0;
    }
  }
  .summary-card-no-data {
    font-size: 16px;
    line-height: 28px;
    font-weight: bold;
    color: $light-gray;
  }
  .info-button-container {
    color: $rich-electric-blue;
    background: white;
    border: none;
    outline: none;
    width: 16px;
    height: 16px;
    padding: 0px;
    margin-left: 8px;
    line-height: 17px;
    border-radius: 16px;
    .info-icon {
      border-radius: 16px;
      &:hover {
        cursor: pointer;
        background: $anti-flash-white;
      }
    }
    img {
      background: $white;
    }
  }
}
.summary-card-modal {
  margin-top: -24px;
  padding: 24px;
  box-sizing: border-box;
  width: 291px;
  background: $white;
  border: 1px solid $anti-flash-white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  color: $space-cadet;
  .summary-card-modal-header {
    font-size: 20px;
    line-height: 20px;
    font-weight: bold;
  }
  .summary-card-modal-content {
    font-size: 16px;
    line-height: 19px;
    margin: 16px 0;
  }
  .modal-demo-button {
    padding: 0;
    margin: 0;
  }
}


.info-button {
  background: $space-cadet;
  color: $white;
  padding: 16px;
  font-size: 14px;
  font: $rubik-regular;
  border-radius: 4px;
}

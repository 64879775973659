@import "../../styles/utils/constants";


.related-competitor-ads {
  padding: 16px 0;
  text-align: left;
  @media only screen and (max-width: $l) {
    padding: 16px 24px;
  }
}
.related-advertisers-categories {
  padding: 16px 0;
  text-align: left;
  @media only screen and (max-width: $l) {
    padding: 16px 24px;
  }
}
.related-ads {
  justify-items: space-between;
}
.header {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: $yankees-blue;
  border-bottom: 1px solid $philipine-gray
}

.column-container {
  width: 50%;

  &:first-child {
    padding-right: 16px;
  }
  &:last-child {
    padding-left: 16px;
  }
  .related-links {
    padding-top: 24px;
    padding-bottom: 24px;
    a {
      width: fit-content;
      text-decoration: underline;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      &:not(:last-child) {
        padding-bottom: 16px;
      }
    }
  }
}

@media only screen and (max-width: $medium-screen) {

}

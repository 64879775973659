@import "../../styles/utils/constants";

.uk-navbar-nav > li > a,
.uk-navbar-item,
.uk-navbar-toggle {
  min-height: 0px !important;
  line-height: 17px !important;
  color: $spanish-gray !important;;
}

.uk-navbar-left, .uk-navbar-right {
  gap: 0 !important;;
}

.uk-navbar-nav > li.uk-active > a,
.uk-navbar-dropdown-nav > li.uk-active > a,
.uk-active > .uk-icon {
  color: $dark-gray !important;;
}

.uk-navbar-dropdown, .uk-navbar-dropdown-width-2:not(.uk-navbar-dropdown-stack) {
  padding: 10px 20px !important;;
  border-bottom: 3px solid $bittersweet !important;;
  width: 270px !important;;
}

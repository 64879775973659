@import "../../styles/utils/constants";

.submitted-container {
  margin-left: auto;
  margin-right: auto;
}

.button {
  border-radius: 4px;
  font-size: 14px;
  // padding: 0 14px;
  // min-width: 130px;
  width: 100%;
  height: 33px;
  background-color: $bittersweet;
  border: 1px solid $bittersweet;
  color: $white;
  letter-spacing: 0.5px;
  font-weight: 500;
  line-height: 33px;
  &:hover {
    cursor: pointer;
    text-decoration: none;
    color: $anti-flash-white;
    svg {
      color: $anti-flash-white;
    }
  }
}

.email-icon {
  position: absolute;
  margin: 5px 12px;
}

.email-input {
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  padding-left: 45px;
  // min-width: 280px;
  // margin-right: 8px;
  border: 1px solid #D2D3D5;
  height: 33px;
  line-height: 33px;
  margin-bottom: 8px;
  width: calc(100% - 45px);
}

.email-label {
  color: $white;
  text-align: left;
  padding-left: 5px;
  font-size: 12px;
}
.asterisk {
  color: $bittersweet;
}


@media only screen and (max-width: $l) {
  .email-icon {
    margin: 13px 12px;
  }
  .email-input {
    min-width: unset;
    margin-top: 8px;
  }
  .button {
    width: 100%;
  }
  .email-input, .button {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

@media only screen and (min-width: $large-screen) {
  .email-input {
    // min-width: 480px;
  }
}

@import "../../styles/utils/constants";

.container {
  background: $anti-flash-white;
  // padding: 16px;
  border-radius: 4px;
  flex: 1;
  @media only screen and (max-width: $l) {
    flex: none;
    width: calc(100% - 48px);
    padding: 0 24px;
  }
  

  .inner-container {
    max-width: 1200px;
    margin: auto;
    height: calc(100% - 106px);
    justify-content: space-between;
  }
  .header {
    padding: 24px 0;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    text-transform: uppercase;
    color: $blue-yonder;
    display: inline-flex;
    @media only screen and (max-width: $l) {
      margin-left: auto;
      margin-right: auto;
    }
    .email-label {
      margin-right: 16px;
    }
    .email-form {
      display: inline-flex;
    }
  }

}

@media only screen and (max-width: $medium-screen) {
  .container {
    .header {
      display: block;

      width: calc(100% - 28px);
      .email-label {
        margin-right: 0;
        padding-bottom: 16px;
      }
      .email-form {
        display: block;
      }
    }
  }
}

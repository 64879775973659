@import "../../styles/utils/constants";

.header {
  font-variant-ligatures: none;
  text-align: left;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));
  position: relative;
  z-index: 9999;
  .header-logo {
    width: 147px;
    height: 65px;
  }
  .navbar-wrapper {
    padding: 16px 30px 12px;
  }
  .navbar-left {
    flex-wrap: nowrap;
  }
  background: $white;
  flex-wrap: nowrap;
  .logo {
    height: 65px;
    min-width: 146px;
    margin-right: 40px;
  }

  .desktop-menu {
    letter-spacing: 0.5px;
    .dropdown {
      margin-top: 0px;
    }
  }

  .loading-indicator-container {
    padding: 80px 20px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .mobile-menu-button {
    display: none
  }
  .top-ads-button {
    background: $blue;
    padding: 12px 18px;
    svg {
      color: $white;
    }
    &.top-ads-mobile-button {
      color: $white;
      padding: 16px 18px;
      width: fit-content;
    }
    &:hover {
      background: $bittersweet;
    }
  }
  .top-ads-dropdown {
    margin-top: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .top-ads-dropdown-divider {
    margin: 8px 0;
  }

  .icon {
    color: $spanish-gray;
    cursor: pointer;
    &:hover {
      color: $spanish-gray;
    }
  }
  .mobile-menu-container {
    letter-spacing: 0.75px;
    background-color: $blue;
  }
  .mobile-menu {
    display: none;
    background: $blue;
    z-index: 1;
    .dropdown {
      margin-top: 0px;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
      }
      .mobile-menu-header-row {
        margin: 0;
        .mobile-menu-header {
          padding: 28px 28px 28px 42px;
        }
        .mobile-sub-menu-header {
          padding: 28px 28px 28px 0;
        }
        .mobile-menu-link { 
          font-size: 21px;
          line-height: 25px;
          font-weight: 400;
          padding: 0;
          color: $white;
        }
        .mobile-sub-menu-arrow {
          padding: 31px 28px 25px 40px;
          color: $white;
        }
      }
      
    }

    .bold {
      font-weight: bold;
    }

    .divider {
      border-bottom: 1px dashed $white;
      margin: 15px 0 15px;
    }

    .link {
      font-size: 14px;
      margin-bottom: 5px;
      color: $white;
    }
  }

  .demo-button {
    padding: 0;
    > div {
      width: 200px;
      font-weight: normal;
    }
  }

}

// @media only screen and (max-width: 1330px) {
//   .header {
//     .body {
//       .demo-button {
//         display: none;
//       }
//     }
//   }
// }

@media only screen and (max-width: $medium-screen) {
  .header {
    .header-logo {
      width: 113px;
      height: 50px;
    }
    .navbar-wrapper {
      padding: 10px 20px 14px;
    }
    .mobile-menu {
      display: block;
    }
    .mobile-menu-button {
      display: flex;
    }
    .desktop-menu-button {
      display: none;
    }
    .desktop-menu, .demo-button {
      display: none;
    }
  }
}

@media only screen and (max-width: $small-phone) {
  .header {
    .mobile-menu {
      .link {
        font-size: 12px;
      }
    }
  }
}




@import "utils/constants";

.edo-layout {
  padding-top: 0;
  min-height: calc(100vh - 420px);
}

.edo-container {
  //margin: 0 auto;
  margin-left: auto;
  margin-right: auto;
  //max-width: $max-width;
  //padding: 0 10%;
  padding-left: 10%;
  padding-right: 10%;
}

.edo-container-mobile {
  margin-left: auto;
  margin-right: auto;
  padding-left: 10%;
  padding-right: 10%;
}

@media only screen and (max-width: $s) {
  .edo-container-mobile{
    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;
  }
}

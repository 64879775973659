@import "./utils/constants";

h1, .edo-h1, .edo-headline {
  font-family: $rubik-regular;
  font-size: 56px;
  font-variant-ligatures: none;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  color: $dark-gray;
}

h2, .edo-h2 {
  font-family: $rubik-regular;
  font-size: 44px;
  font-variant-ligatures: none;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  color: $dark-gray;
}

h3, .edo-h3 {
  font-family: $rubik-regular;
  font-size: 36px;
  font-variant-ligatures: none;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  color: $dark-gray;
}

h4, .edo-h4 {
  font-family: $rubik-regular;
  font-size: 20px;
  font-variant-ligatures: none;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  color: $dark-gray;
}

.edo-tagline {
  font-family: $rubik-regular;
  font-size: 26px;
  font-variant-ligatures: none;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  color: $dark-gray;
}

.edo-tagline-bold {
  font-family: $rubik-regular;
  font-size: 26px;
  font-variant-ligatures: none;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  color: $dark-gray;
}

.edo-navbar-hover-text {
  font-family: $rubik-regular;
  font-size: 15px;
  font-variant-ligatures: none;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  color: $dark-gray;
}

html, body, .edo-body-text {
  font-family: $rubik-regular;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  color: $dark-gray;
}

.edo-body-text-space {
  font-family: $rubik-regular;
  font-size: 16px;
  font-variant-ligatures: none;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  color: $dark-gray;
}

.edo-navbar-text {
  font-family: $rubik-regular;
  font-size: 15px;
  font-variant-ligatures: none;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  //-webkit-font-smoothing: antialiased;
  color: $dark-gray;
}

.center-text {
  text-align: center;
}

.space-mono {
  font-family: $space-mono-regular;
  font-variant-ligatures: none;
}

.rubik {
  font-family: $rubik-regular;
}

@media only screen and (max-width: $small-screen) {
  h1, .edo-h1, .edo-headline  {
    font-size: 52px;
  }

  h2, .edo-h2 {
    font-size: 44px;
  }

  h3, .edo-h3 {
    font-size: 30px;
  }

  h4, .edo-h4 {
    font-size: 20px;
  }

  .edo-tagline {
    font-size: 26px;
  }

  .edo-tagline-bold {
    font-size: 26px;
  }

  .edo-navbar-hover-text, .edo-navbar-text {
    font-size: 12px;
  }

  html, body, .edo-body-text, .edo-body-text-space {
    font-size: 16px;
  }
}

@media only screen and (max-width: $tablet) {
  h1, .edo-h1, .edo_headline {
    font-size: 46px;
  }

  h2, .edo-h2 {
    font-size: 38px;
  }

  .edo-tagline {
    font-size: 23px;
  }

  .edo-tagline-bold {
    font-size: 23px;
  }
}

@media only screen and (max-width: $large-phone) {
  h1, .edo-h1, .edo-headline  {
    font-size: 44px;
  }

  h2, .edo-h2 {
    font-size: 33px;
  }

  h3, .edo-h3 {
    font-size: 25px;
  }

  h4, .edo-h4 {
    font-size: 18px;
  }

  .edo-tagline {
    font-size: 20px;
  }

  .edo-tagline-bold {
    font-size: 20px;
  }

  .edo-navbar-hover-text, .edo-navbar-text {
    font-size: 16px;
  }

  html, body, .edo-body-text, .edo-body-text-space {
    font-size: 16px;
  }
}

@media only screen and (max-width: $medium-phone) {
  h1, .edo-h1, .edo-headline  {
    font-size: 40px;
  }
}

@media only screen and (max-width: $small-phone) {
  h1, .edo-h1, .edo-headline  {
    font-size: 33px;
  }

  h2, .edo-h2 {
    font-size: 22px;
  }

  h3, .edo-h3 {
    font-size: 18px;
  }

  h4, .edo-h4 {
    font-size: 16px;
  }

  .edo-tagline {
    font-size: 17px;
  }

  .edo-tagline-bold {
    font-size: 17px;
  }

  .edo-navbar-hover-text, .edo-navbar-text {
    font-size: 14px;
  }

  html, body, .edo-body-text, .edo-body-text-space {
    font-size: 14px;
  }
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.open-sans {
  font-family: $open-sans-regular;
}

.has-background-anti-flash-white {
  background-color: $anti-flash-white;
}

@import "../../styles/utils/constants";

@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

div.is-loading {
  position: absolute;
  top: 0;
  left: 0;
}

.loading-indicator-container {
  opacity: 0.64;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: 1;
}

div.is-loading-inline {
  position: relative;
}

.is-loading-inline::before {
  -webkit-animation: spinAround .5s infinite linear;
  animation: spinAround .5s infinite linear;
  border: 8px solid $alice-blue;
  border-radius: 290486px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  margin: auto;
  width: 100%;
  z-index: 1;
  pointer-events: none;
}

.error-message {
  color: $maximum-red;
  opacity: 0.9;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.no-data-message {
  color: $space-cadet;
  opacity: 0.7;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.container {
  background-color: white;
  height: 100%;
  width: 100%;
  //flex-grow: 1;
  &.table {
    height: fit-content;
  }
  > div {
    display: block;
    &.display-div-centered-table {
      text-align: center;
      > div {
        display: table;
      }
    }
  }
}

.progress-bar-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  pointer-events: none;
}

.title-content-wrapper {
  overflow: inherit;
}

@import "../../styles/utils/constants";

.creative-card {
  &:hover {
    text-decoration: none;
  }
}

.inner-container {
  padding: 16px;
  width: 260px;
  margin-left: auto;
  margin-right: auto;
  &.first {
    padding-left: 0;
    margin-left: 0;
    @media only screen and (max-width: $l) {
      margin-left: auto;
      padding-left: 16px;

    }
  }
  &.last {
    padding-right: 0;
    margin-right: 0;
    @media only screen and (max-width: $l) {
      margin-right: auto;
      padding-right: 16px;
    }
  }

  @media only screen and (max-width: 320px) {
    max-width: 100%;
    padding: 8px 0;
    margin: 0;
    &.first {
      margin: 0;
      padding: 0;
    }
    &.last {
      margin: 0;
      padding: 0;
    }
    
  }
}

.title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: $yankees-blue;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 7px;
}
.thumbnail-container {
  img {
    width: 260px;
    height: 146px;
    object-fit: cover;
  }
}

.creative-detail {
  margin-top: 8px;
  margin-bottom: 8px;
  color: $fiord;

  .creative-id {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px; 
    padding-right: 8px;
  }
  .duration {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
}

.airings-detail {
  margin-bottom: 8px;
  color: $fiord;
  .airings-label {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }
  .airings-count {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
}




$size-xs: 4px;
$size-s: 8px;
$size-m: 16px;
$size-l: 24px;
$size-xl: 32px;
$size-xxl: 64px;

$sizes: (
  "xs": $size-xs,
  "s": $size-s,
  "m": $size-m,
  "l": $size-l,
  "xl": $size-xl,
  "xxl": $size-xxl,
);

/*******************************************************
 * SCREEN SIZES
 *******************************************************/
$small-phone: 330px;      // iphone 1, SE; "320px"
$medium-phone: 375px;     // iphone 6/7/8/X
$large-phone: 430px;      // iphone 12 pro max
$tablet: 960px;           // iPad,
$small-screen: 920px;     // macbook air
$medium-screen: 1080px;   // standard
$large-screen: 1400px;    // macbook pro and larger
$max-width: $large-screen;

/*******************************************************
 * FONTS
 *******************************************************/
$space-mono-regular: "Space Mono", Helvetica, sans-serif;
$open-sans-regular: "Open Sans", Helvetica, sans-serif;
$rubik-regular: 'Rubik', sans-serif;

/*******************************************************
 * COLORS
 *
 * Ordering: White --> Black, ROYGBIV (light --> dark)
 *******************************************************/
$white: #ffffff;
$light-gray: #E6E6E6;
$anti-flash-white: #F3F3F3;   // https://www.color-name.com/hex/F3F3F3
$spanish-gray: #979797;      // https://www.color-name.com/hex/979797
$dark-gray: #5C5C5C;
$black: #000000;


$alice-blue: #E8F2FF; // https://www.color-name.com/hex/E8F2FF
$bittersweet: #F26A5C;          // https://www.color-name.com/hex/F26A5C
$maximum-red: #DD2A18; // https://www.color-name.com/hex/DD2A18
$beau-blue: #C2D5ED;           // https://www.color-name.com/hex/C2D5ED
$rich-electric-blue: #009ADE; // https://www.color-name.com/hex/009ADE
$blue: #4771A5;
$space-cadet: #1E2752;      // https://www.color-name.com/hex/1E2752
$blue-yonder: #5170A1; // https://www.color-name.com/hex/5170a1
$med-light-gray: #999999;
$philipine-gray: #8F9095;  // https://www.color-name.com/hex/8f9095
$granite-gray: #676767;     // https://www.color-name.com/hex/676767
$dark-navy-blue: #1E2752;


$yankees-blue: #142843; // https://www.color-name.com/hex/142843
$ylmnn-blue: #285086; // https://www.color-name.com/hex/285086
$fiord: #395272;

$facebook-blue: #1877F2;
$twitter-blue: #1DA1F2;
$linkedin-blue: #2867B2;
$embed-gray: #F2F5F7;
$share-button-text: #2E2E30;
$embed-gray-2: #D2D3D5;
/*******************************************************
 * NEW SCREEN SIZES (PARITY WITH UIKIT)
 *******************************************************/
$s: 640px;
$m: 960px;
$l: 1200px;
$xl: 1600px;


// Adengage Constants
$adengage-navbar-height: 64px;
$adengage-sidebar-width: 330px;
$adengage-footer-height: 175px;
$adengage-mobile-footer-height: 125px;
